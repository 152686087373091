import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

/*
  This template is used to create all the index pages, so every path will have its own
  custom list of the appropriate articles underneath
*/
class IndexTemplate extends React.Component {
  render() {
    const { data, pageResources } = this.props
    // const siteTitle = data.site.siteMetadata.title
    const posts = data.allYaml.edges
    /* giving up on trying to match paths via GraphQL & regex
      pageResources.page.path is already provided, it's the path of the index page being created
      our GraphQL returns ALL YAML, and we will filter them here
      if we do the regex match here (not GraphQL) and it works fine
      the regex expresion looks complicated because we are escaping lots of backslashes in the paths
    */
    /*
      not so fast -- pageResources only exists during development
      at build time, location.pathname is available
    */
    const pagepath = (pageResources) ? pageResources.page.path : this.props.location.pathname
    const regex = new RegExp(`^(?:${pagepath.replace(/\//gi, '\\/')})`, 'gi')  //  regex string was '\\\/', got warning
    const visiblePosts = posts.filter(p => p.node.fields.slug.match(regex))

    return (
      <Layout>
        <SEO title="all fantasy football verdicts" />
        {visiblePosts.map(({ node }) => {
          const title = `The Judge's Verdict: ${node.meta.sport} ${node.meta.season} Week ${node.meta.week}`
          return (
            <article key={node.fields.slug} className="font-typ mt-8 mx-2 md:mx-auto whitespace-pre-line">
              <header className="flex flex-row md:justify-between items-center md:items-baseline">
                <h2 className="text-2xl text-red-800 hover:text-blue-500">
                  <Link 
                    style={{ 
                      boxShadow: `none`,
                      textDecoration: `none`,
                      color: `inherit`
                    }} 
                    to={`${node.fields.slug}`}
                  >
                    {title}
                  </Link>
                </h2>
                <div className="text-sm text-right">{node.meta.date}</div>
              </header>
              <section>
                {/* <div className="px-6"><span className="underline">CASE SUMMARY</span>: {node.content.argument}</div> */}
                <div className="px-6"
                  dangerouslySetInnerHTML={{
                    __html: `<span className="underline">CASE SUMMARY</span>: ${node.content.preview || node.content.argument}`
                  }}
                />
                <div className="text-right text-sm text-red-800 hover:text-blue-500 mr-4 mt-4"><Link to={`${node.fields.slug}`}>...click for the verdict</Link></div>
              </section>
            </article>
          )
        })}
      </Layout>
    )
  }
}

export default IndexTemplate

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allYaml(sort: {fields: meta___date, order: DESC}) {
      edges {
        node {
          content {
            argument
            preview
          }
          meta {
            week
            sport
            season
            date(formatString: "MMMM D, YYYY")
          }
          fields {
            slug
          }
        }
      }
    }
  }
`
